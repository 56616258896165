<template>
  <DropdownWrapper
    :dropdownKey="dropdownKey"
    :autoClose="autoClose"
    :stopPropagation="stopPropagation"
    :direction="direction"
    :dropDirection="dropDirection"
    @closed="emit('closed')"
  >
    <template #button="slotProps">
      <slot
        name="button"
        :handleClick="slotProps.handleClick"
        :handleKeyDown="slotProps.handleKeyDown"
        :handleButtonHotkeyDown="slotProps.handleButtonHotkeyDown"
        :uuid="slotProps.uuid"
      >
        <flux-button
          v-if="!iconButton"
          :class="[buttonClass, 'h-full', fullWidth ? 'w-full text-left' : '']"
          :size="buttonSize"
          @click="slotProps.handleClick"
          :type="typeButton"
          :icon="icon"
          :iconPosition="iconPosition"
          :disabled="disabled"
          :hotkey="hotkey"
        >
          <span :class="['flex-grow', textClass]">{{ label }}</span>
          <slot name="labelSuffix"></slot>
        </flux-button>
        <button
          v-if="iconButton"
          type="button"
          :disabled="disabled"
          @click="(e) => slotProps.handleClick(e)"
          :class="[
            buttonClass,
            ' m-0 rounded bg-transparent p-1 outline-none focus:ring',
            disabled
              ? 'cursor-not-allowed text-text-quinary'
              : [
                  iconButtonTextColor,
                  'cursor-pointer hover:bg-background-tertiary hover:text-text-primaryHover active:text-text-primaryActive',
                ],
          ]"
        >
          <i :class="[icon, iconButtonSize, 'md:p-1']" />
        </button>
      </slot>
    </template>
    <template #default="slotProps">
      <slot
        :handleClick="slotProps.handleClick"
        :handleKeyDown="slotProps.handleKeyDown"
        :handleMenuHotkeyDown="slotProps.handleMenuHotkeyDown"
      ></slot>
    </template>
  </DropdownWrapper>
</template>

<script lang="ts" setup>
import { Hotkey } from "@/models/Hotkey";
import { computed, PropType } from "vue";
import DropdownWrapper from "./DropdownWrapper.vue";
import { ButtonType } from "@/models/Button";

const props = defineProps({
  label: {
    default: "Meer",
    type: String,
  },
  icon: {
    default: "far fa-chevron-down ",
    type: String,
  },
  iconPosition: {
    default: "right",
    type: String as PropType<"left" | "right">,
  },
  typeButton: {
    default: "default",
    type: String as PropType<ButtonType>,
  },
  buttonSize: {
    default: "small",
    type: String as PropType<"mini" | "small" | "medium">,
  },
  fullWidth: {
    default: false,
    type: Boolean,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  direction: {
    default: "left",
    type: String as PropType<"left" | "right">,
  },
  dropDirection: {
    default: "down",
    type: String as PropType<"up" | "down">,
  },
  iconButton: {
    default: false,
    type: Boolean,
  },
  iconButtonTextColor: {
    default: "text-text-quaternary",
    type: String,
  },
  buttonClass: {
    type: String,
  },
  textClass: {
    type: String,
  },
  autoClose: {
    default: true,
    type: Boolean,
  },
  hotkey: {
    type: Object as PropType<Hotkey>,
  },
  stopPropagation: {
    default: false,
    type: Boolean,
  },
  dropdownKey: {
    type: Symbol,
  },
});

const emit = defineEmits<{
  (e: "closed"): void;
}>();

const iconButtonSize = computed(() => {
  switch (props.buttonSize) {
    case "medium":
      return "text-base";
    case "small":
      return "text-sm";
    case "mini":
      return "text-xs";
    default:
      return "text-sm";
  }
});
</script>
