<template>
  <nav
    class="flex items-center justify-between bg-background-primary dark:bg-transparent"
    aria-label="Pagination"
  >
    <div class="hidden sm:block">
      <p
        class="text-sm text-text-secondary"
        v-if="paginatorIsLengthAware(paginator)"
      >
        Resultaten
        <span class="font-semibold">{{ formatNumber(lowerBound) }}</span>
        -
        <span class="font-semibold">{{ formatNumber(upperBound) }}</span>
        van de
        <span class="font-semibold">{{ formatNumber(paginator.total) }}</span
        >.
      </p>
    </div>
    <div class="flex flex-1 justify-between gap-2 sm:justify-end">
      <flux-button
        @click="paginator.prev()"
        size="small"
        icon="fal fa-chevron-left"
        :disabled="paginator.current_page === 1 || loading"
      >
        {{ $t("general.previous") }}
      </flux-button>
      <flux-button
        @click="paginator.next()"
        size="small"
        icon="fal fa-chevron-right"
        iconPosition="right"
        :disabled="
          paginator.current_page === paginator.last_page ||
          (paginator.last_page === undefined &&
            paginator.next_page_url === undefined) ||
          loading
        "
      >
        {{ $t("general.next") }}
      </flux-button>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { Paginator, paginatorIsLengthAware } from "@/models/Paginator";
import { computed } from "vue";

const props = defineProps<{
  background: boolean;
  paginator: Paginator;
  loading: boolean;
}>();

const lowerBound = computed(() => {
  return (props.paginator.current_page - 1) * props.paginator.per_page + 1;
});

const upperBound = computed(() => {
  if (!paginatorIsLengthAware(props.paginator)) {
    return 0;
  }
  return Math.min(
    lowerBound.value - 1 + props.paginator.per_page,
    props.paginator.total,
  );
});

const pages = computed(() => {
  if (!paginatorIsLengthAware(props.paginator)) {
    return undefined;
  }
  return new Array(
    Math.max(Math.ceil(props.paginator.total / props.paginator.per_page), 1),
  )
    .fill(0)
    .map((_, index) => index + 1);
});

function formatNumber(number: number): string {
  return new Intl.NumberFormat("nl-NL").format(number);
}
</script>
