<template>
  <flux-dropdown
    :class="isUpdatingStatus ? 'opacity-40' : 'opacity-100'"
    :autoClose="true"
    :direction="direction"
    :stopPropagation="true"
  >
    <template #button="slotProps">
      <div
        class="hover:bg-blueGray-200 focus-within:bg-blueGray-200 inline-flex h-6 w-6 items-center justify-around rounded-full outline-none focus-within:text-text-primary focus-within:ring hover:text-text-primaryHover focus:ring dark:text-text-quaternary"
        tabindex="0"
        :id="slotProps.uuid"
        @keydown.enter="
          ($event) => {
            slotProps.handleKeyDown($event);
            $event.preventDefault();
          }
        "
        @click="
          ($event) => {
            slotProps.handleClick($event);
            $event.preventDefault();
          }
        "
      >
        <AppointmentStatusVue :status="(appointment as T).status" />
      </div>
    </template>
    <template #default="slotProps">
      <flux-dropdown-item
        v-for="status in appointmentStatusShown(appointment as T)"
        :key="status"
        :hotkey="hotkeys[status.toLowerCase()]"
        @click="
          (e) => {
            slotProps.handleClick(e);
            changeStatus(status);
          }
        "
        @hotkeyDown="
          () => {
            slotProps.handleMenuHotkeyDown();
            changeStatus(status);
          }
        "
        ><AppointmentStatusIconVue
          class="-ml-1 mr-1"
          :status="status"
        /><span>{{ $t("appointment.status." + status) }}</span>
      </flux-dropdown-item>
    </template>
  </flux-dropdown>
</template>

<script
  lang="ts"
  setup
  generic="
    T extends
      | Appointment
      | AppointmentApi
      | AppointmentWithInvoice['appointment']
  "
>
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import store, { pinia } from "@/libraries/store";
import { telemetryManager } from "@/libraries/telemetry/Manager";
import { Appointment, AppointmentStatus } from "@/models/Appointment";
import { ref, watch } from "vue";
import AppointmentStatusVue from "../AppointmentStatus.vue";
import AppointmentStatusIconVue from "../AppointmentStatusIcon.vue";
import { isWithinInterval, addHours, subHours, isBefore } from "date-fns";
import moment from "moment";
import { Hotkey } from "@/models/Hotkey";
import { useNotify } from "@/composables/notify";
import { useAppointmentTypeStore } from "@/libraries/store/AppointmentTypes";
import { AppointmentApi } from "@/libraries/repositories/appointmentRepositoryUsingApi";
import { AppointmentWithInvoice } from "@/libraries/repositories/appointmentsWithInvoices";

const { notify } = useNotify();

const props = withDefaults(
  defineProps<{
    appointment: T;
    direction?: "left" | "right";
    useAppointmentStore?: boolean;
    useHotkeys?: boolean;
  }>(),
  {
    direction: "right",
    useAppointmentStore: true,
    useHotkeys: false,
  },
);

const emit = defineEmits<{
  (e: "change", app: T, status: AppointmentStatus): void;
  (e: "appointmentStatusUpdated", app: T, status: AppointmentStatus): void;
}>();

const appointmentTypeStore = useAppointmentTypeStore(pinia);

const defaultHotkeys = {
  booked: {
    altKey: true,
    key: "1",
  },
  arrived: {
    altKey: true,
    key: "2",
  },
  fulfilled: {
    altKey: true,
    key: "3",
  },
  noshow: {
    altKey: true,
    key: "4",
  },
  cancelled: {
    altKey: true,
    key: "5",
  },
};

const hotkeys = ref<{ [key: string]: Hotkey }>({});

watch(
  () => props.useHotkeys,
  () => {
    if (props.useHotkeys) {
      hotkeys.value = defaultHotkeys;
    } else {
      hotkeys.value = {};
    }
  },
  { immediate: true },
);

const isUpdatingStatus = ref(false);
const appointmentStatusses = ref<AppointmentStatus[]>([
  "BOOKED",
  "ARRIVED",
  "FULFILLED",
  "NOSHOW",
  "CANCELLED",
]);

function makeAppointmentStartADateType(startDate: moment.Moment | Date) {
  if (startDate instanceof Date) {
    return startDate;
  } else {
    return startDate.toDate();
  }
}

function appointmentStatusShown(appointment: T) {
  const appointmentStart: Date = makeAppointmentStartADateType(
    appointment.start,
  );
  if (appointment.locked === true) {
    return appointmentStatusses.value.filter((n) => n === "BOOKED");
  }

  if (appointmentIsWithin48HourRange(appointmentStart)) {
    return appointmentStatusses.value;
  } else if (isBefore(appointmentStart, subHours(new Date(), 24))) {
    return appointmentStatusses.value.filter((n) => n != "ARRIVED");
  } else {
    return appointmentStatusses.value.filter(
      (n) => !["ARRIVED", "FULFILLED"].includes(n),
    );
  }
}
async function changeStatus(status: AppointmentStatus) {
  if (
    appointmentIsWithin48HourRange(
      makeAppointmentStartADateType(props.appointment.start),
    ) &&
    status === "CANCELLED"
  ) {
    notify({
      type: "error",
      message: "Afspraak kan niet geannuleerd worden",
      duration: 6000,
    });
    return;
  }
  if (isUpdatingStatus.value) {
    return;
  }
  if (props.appointment.locked === true && status !== "BOOKED") {
    notify({
      type: "error",
      message: "Kan afspraak niet aanpassen vanwege afgeboekte credit",
      duration: 6000,
    });
    return;
  }
  if (!props.useAppointmentStore) {
    emit("change", props.appointment, status);
    return;
  }

  isUpdatingStatus.value = true;
  await store.dispatch(
    "calendar/directlyUpdateAppointmentStatus",
    Object.assign({}, { id: props.appointment.id, status }),
  );
  isUpdatingStatus.value = false;
  telemetryManager.queueEntry({
    action: "appointment.update.status",
    context: {
      status: status,
      appointment_id: props.appointment.id,
      invoice_generation: hasFeatureFlag("invoice-generation"),
    },
  });
  if (
    (await appointmentTypeStore.findById(props.appointment.appointment_type_id))
      ?.package_uuid
  ) {
    notify({
      message: `Credit is ${
        ["FULFILLED"].includes(status) ? "afgeboekt" : "gereserveerd"
      }`,
      type: "success",
    });
  }
  emit("appointmentStatusUpdated", props.appointment, status);
}
function appointmentIsWithin48HourRange(appointmentStart: Date) {
  return isWithinInterval(appointmentStart, {
    start: subHours(new Date(), 24),
    end: addHours(new Date(), 24),
  });
}
</script>
