<template>
  <div class="relative">
    <flux-input
      v-model:modelValue="email"
      :placeholder="placeholder"
      :prop="prop"
      type="email"
    />
    <Transition
      enter-active-class="transition delay-500 transform duration-200 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition transform duration-75 ease-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <span
        class="absolute right-0 pt-0.5 text-xs text-text-quaternary"
        v-if="suggestion"
      >
        Bedoelde je
        <div
          class="inline-block cursor-pointer rounded p-0.5 font-medium text-text-primary hover:bg-background-tertiary"
          @click="email = suggestion.full"
        >
          {{ suggestion.full }}
        </div>
        ?
      </span>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import emailSpellChecker from "@zootools/email-spell-checker";

const email = defineModel<string>("modelValue", { required: true });

defineProps<{
  placeholder?: string;
  prop?: string;
}>();

const suggestion = computed(() =>
  emailSpellChecker.run({
    email: email.value,
  }),
);
</script>
