<template>
  <div class="flex gap-1">
    <kbd
      class="class flex min-h-4 min-w-4 items-center justify-center p-px font-sans"
      v-for="key in keys"
      :class="[getClass(), textColor]"
    >
      <template v-if="typeof key === 'string'">{{ key }}</template>
      <template v-else-if="getModifierKeySymbol(key)">
        {{ getModifierKeySymbol(key) }}
      </template>
      <template v-else><i :class="getModifierKeyIcon(key)" /></template>
    </kbd>
  </div>
</template>

<script setup lang="ts">
import { Hotkey } from "@/models/Hotkey";
import { computed, PropType } from "vue";

type SpecialKeys = "ArrowRight" | "ArrowLeft";

const props = defineProps({
  hotkey: {
    required: true,
    type: Object as PropType<Hotkey>,
  },
  backgroundColor: {
    default: "bg-blue-200/25 dark:bg-zinc-700 ",
    type: String,
  },
  textColor: {
    default: "text-gray-500 dark:text-text-primary ",
    type: String,
  },
  withBackground: {
    default: true,
    type: Boolean,
  },
});

const ctrl = Symbol();
const alt = Symbol();
const shift = Symbol();
const arrowRight = Symbol();
const arrowLeft = Symbol();
const mouseClick = Symbol();

const keys = computed(() => {
  const keyArray: (string | symbol)[] = [];
  if (props.hotkey.ctrlMeta) {
    keyArray.push(ctrl);
  }
  if (props.hotkey.altKey) {
    keyArray.push(alt);
  }
  if (props.hotkey.shiftKey) {
    keyArray.push(shift);
  }
  if (props.hotkey.key === "ArrowRight") {
    keyArray.push(arrowRight);
  } else if (props.hotkey.key === "ArrowLeft") {
    keyArray.push(arrowLeft);
  } else if (props.hotkey.key === "MouseClick") {
    keyArray.push(mouseClick);
  } else {
    keyArray.push(props.hotkey.key.toUpperCase());
  }
  if (props.hotkey.secondKey) {
    keyArray.push(props.hotkey.secondKey.toUpperCase());
  }
  return keyArray;
});

function getClass() {
  if (props.withBackground) {
    return "rounded min-w-[1.25rem] px-px " + props.backgroundColor;
  }
  return "";
}

function getModifierKeySymbol(key: symbol): string | undefined {
  const isMac = navigator.userAgent.includes("Mac");
  switch (key) {
    case ctrl:
      return isMac ? "⌘" : "ctrl";
    case alt:
      return isMac ? "⌥" : "alt";
  }
}

function getModifierKeyIcon(key: symbol) {
  switch (key) {
    case shift:
      return "fal fa-arrow-alt-up";
    case arrowRight:
      return "fal fa-arrow-right";
    case arrowLeft:
      return "fal fa-arrow-left";
    case mouseClick:
      return "fal fa-mouse";
  }
}
</script>
