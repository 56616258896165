<template>
  <div class="flex">
    <div
      class="z-0 translate-x-[2px] transform border-l-2 border-border-primary"
    />
    <div :class="'z-10 p-0 ' + menuClass">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";

const props = defineProps({
  menuClass: {
    type: String as PropType<string>,
    required: false,
  },
});
</script>
