import { apiClient } from "@/libraries/utils/axios";
import { handledZodParse, handleErrors } from "@/libraries/utils/errorHandling";
import { InjectionKey } from "vue";
import { z } from "zod";
import { declarationEntryAnnotationSchema } from "../DeclarationEntryAnnotations/DeclarationEntryAnnotationsRepository";

const externalReferenceTypes = ["vecozo.pm304.prestatie"] as const;
const externalDeclarationReferenceTypes = ["vecozo.pm304"] as const;
export const declarationEntryStatus = [
  "pending",
  "rejected",
  "granted",
  "partially_granted",
  "unknown",
] as const;
export type DeclarationEntryStatus = (typeof declarationEntryStatus)[number];

export const pm305PrestatieReturnSchema = z.object({
  type: z.literal("vecozo.pm304"),
  referentienummer_dit_record: z.string().optional(),
  referentienummer_voorgaand_record: z.string().optional(),
  berekend_bedrag_zorgverzekeraar: z.string().optional(),
  indicatie_debet_credit_3: z.string().optional(),
  toegekend_bedrag_zorgverzekeraar: z.string().optional(),
  indicatie_debet_credit_4: z.string().optional(),
  retourcode_1: z.string().optional(),
  retourcode_meaning_1: z.string().optional(),
  retourcode_2: z.string().optional(),
  retourcode_meaning_2: z.string().optional(),
  retourcode_3: z.string().optional(),
  retourcode_meaning_3: z.string().optional(),
});

export const gds802PrestatieReturnSchema = z.object({
  type: z.literal("vecozo.gds801"),
  retourcode_1: z.string().optional(),
  retourcode_meaning_1: z.string().optional(),
  debetPrestatie: z
    .object({
      feedback: z.array(
        z.object({
          retourcode: z.string(),
          returnCodeMeaning: z.string().optional(),
        }),
      ),
      referentienummer: z.string(),
      berekendBedragVerzekeraarInclBtw: z.number(),
      toegekendBedragInclBtwFinancieel: z.number(),
      toegekendBedragInclBtwNietFinancieel: z.number(),
    })
    .optional(),
  creditPrestatie: z
    .object({
      feedback: z.array(
        z.object({
          retourcode: z.string(),
          returnCodeMeaning: z.string().optional(),
        }),
      ),
      referentienummer: z.string(),
      berekendBedragVerzekeraarInclBtw: z.number(),
      toegekendBedragInclBtwFinancieel: z.number(),
      toegekendBedragInclBtwNietFinancieel: z.number(),
    })
    .optional(),
  verzekerdeFeedback: z
    .array(
      z.object({
        retourcode: z.string(),
        returnCodeMeaning: z.string().optional(),
      }),
    )
    .optional(),
});

export type Pm305PrestatieReturn = ReturnType<
  (typeof pm305PrestatieReturnSchema)["parse"]
>;

export type Gds802PrestatieReturn = ReturnType<
  (typeof gds802PrestatieReturnSchema)["parse"]
>;

const declarationEntrySchema = z.object({
  id: z.string(),
  healthcare_provider_id: z.number(),
  status: z.enum(declarationEntryStatus),
  declaration_id: z.number().optional(),
  invoice_entry_id: z.number().optional(),
  patient_zis_number: z.number().optional(),
  external_reference: z.string().optional(),
  external_reference_type: z.enum(externalReferenceTypes).optional(),
  external_declaration_reference: z.string().optional(),
  external_declaration_reference_type: z
    .enum(externalDeclarationReferenceTypes)
    .optional(),
  external_invoice_reference: z.string().optional(),
  date_event: z.string().optional(),
  price_claimed: z.number().optional(),
  price_granted: z.number().optional(),
  declaration_data: z.object({}).optional(),
  return_array: pm305PrestatieReturnSchema.optional(),
  import_data: z.object({}).optional(),
  treatment_count: z.number().optional(),
  referral_treatment_count: z.number().optional(),
  referral_id: z.number().optional(),
  appointment_uuid: z.string().optional(),
  csi_code: z.string().optional(),
  diagnosis_code: z.string().optional(),
  annotations: z.array(declarationEntryAnnotationSchema),
});

const declarationEntryWithInvoiceDataSchema = declarationEntrySchema.merge(
  z.object({
    csi_code: z.string().optional(),
    dcsph_code: z.string().optional(),
    prestatiecode: z.string().optional(),
    uzovi: z.string().optional(),
  }),
);

const declarationEntryWithInvoiceDataSchemaList = z.array(
  declarationEntryWithInvoiceDataSchema,
);

export type DeclarationEntryWithInvoiceData = ReturnType<
  (typeof declarationEntryWithInvoiceDataSchema)["parse"]
>;

export type DeclarationEntry = ReturnType<
  (typeof declarationEntrySchema)["parse"]
>;

export const DeclarationEntryRepositoryKey = Symbol() as InjectionKey<
  () => DeclarationEntryRepository
>;

export interface DeclarationEntryRepository {
  findAllForPatient(
    zisNumber: number,
  ): Promise<DeclarationEntryWithInvoiceData[]>;
  //findById(id: string): Promise<DeclarationEntry>;
}

export class DeclarationEntryRepositoryUsingApi
  implements DeclarationEntryRepository
{
  async findAllForPatient(
    zisNumber: number,
  ): Promise<DeclarationEntryWithInvoiceData[]> {
    try {
      const { data } = await apiClient.get(
        `/patients/${zisNumber}/declaration_entries`,
      );
      return handledZodParse({
        schema: declarationEntryWithInvoiceDataSchemaList,
        input: data,
        notifyUser: false,
        reportError: true,
        throwOnFail: false,
      });
    } catch (error) {
      handleErrors(error);
    }
  }
}

export class DeclarationEntryRepositoryUsingMemory
  implements DeclarationEntryRepository
{
  #declarationEntrys: DeclarationEntryWithInvoiceData[] = [
    {
      id: "5",
      healthcare_provider_id: 1,
      date_event: "2023-03-06",
      uzovi: "1000",
      prestatiecode: "1000",
      csi_code: "008",
      price_claimed: 4000,
      dcsph_code: "4012",
      status: "pending",
      annotations: [],
    },
    {
      id: "4",
      healthcare_provider_id: 1,
      date_event: "2023-02-15",
      uzovi: "1000",
      prestatiecode: "1000",
      csi_code: "008",
      price_claimed: 4000,
      dcsph_code: "4012",
      status: "granted",
      annotations: [],
    },
    {
      id: "4",
      healthcare_provider_id: 1,
      date_event: "2023-02-15",
      uzovi: "1000",
      prestatiecode: "1001",
      csi_code: "008",
      price_claimed: 4000,
      dcsph_code: "4012",
      status: "rejected",
      annotations: [],
    },
    {
      id: "3",
      healthcare_provider_id: 1,
      date_event: "2023-02-03",
      uzovi: "1000",
      prestatiecode: "1000",
      csi_code: "008",
      price_claimed: 4000,
      dcsph_code: "4012",
      status: "granted",
      annotations: [],
    },
    {
      id: "2",
      healthcare_provider_id: 1,
      date_event: "2023-01-19",
      uzovi: "1000",
      prestatiecode: "1000",
      csi_code: "008",
      price_claimed: 4000,
      dcsph_code: "4012",
      status: "granted",
      annotations: [],
    },
    {
      id: "1",
      healthcare_provider_id: 1,
      date_event: "2023-01-05",
      uzovi: "1000",
      prestatiecode: "1864",
      csi_code: "008",
      price_claimed: 4000,
      dcsph_code: "4012",
      status: "granted",
      annotations: [],
    },
  ];

  async findAllForPatient(): Promise<DeclarationEntryWithInvoiceData[]> {
    return this.#declarationEntrys;
  }
}
