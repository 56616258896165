<template>
  <router-link :to="{ name: name }">
    <div
      :class="[
        'flex h-16 cursor-pointer items-center justify-center',
        'text-gray-300 duration-300 hover:text-text-secondaryHover',
        'relative',
      ]"
    >
      <div
        class="bg-sky-5 absolute bottom-0 left-0 top-0 flex w-1 transform items-center transition duration-300"
        :class="isActive ? 'translate-x-1.5' : '-translate-x-1 opacity-0'"
      >
        <div class="h-1 w-1 rounded-full bg-sky-400 transition"></div>
      </div>
      <i v-bind:class="meta.icon"></i>
    </div>
  </router-link>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { MenuRouteMeta } from "../../interfaces";

const route = useRoute();

const props = defineProps<{
  name: string;
  meta: MenuRouteMeta;
}>();

const isActive = computed(() => {
  return route.name === props.name;
});
</script>
