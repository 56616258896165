<template>
  <div
    class="group relative rounded border border-border-tertiary bg-background-primary p-4"
    v-flux-loading="loading"
    ref="root"
  >
    <!-- Toolbar:options -->
    <div class="options absolute right-4 opacity-0 group-hover:opacity-100">
      <clickable-icon-group>
        <clickable-icon
          v-if="editable && state == 'read'"
          icon="fa-pencil"
          ref="btn_start_edit"
          @click="setState('update')"
        ></clickable-icon>
        <clickable-icon
          v-if="removeable && state == 'read'"
          icon="fa-trash-alt"
          ref="btn_start_remove"
          @click="confirmRemove()"
        ></clickable-icon>
      </clickable-icon-group>
    </div>

    <h3 class="mt-0 font-normal">
      <template v-if="modelValue"
        >{{ modelValue.fullname }}
        <span class="text-lg text-text-quaternary"
          >({{ $t(`contact.relationship.${modelValue.relationship}`) }})</span
        >
        <span
          class="ml-3 rounded-lg bg-blue-200 px-3 text-sm text-blue-800"
          v-if="modelValue.is_payer"
          >Betaler</span
        ></template
      >
      <template v-else>{{ $t("patient.edit.contacts.new_contact") }}</template>
    </h3>

    <!-- Actual form -->
    <flux-form v-if="state == 'create' || state == 'update'" :errors="errors">
      <ContactFormComponent v-model:modelValue="modelValue" />
      <flux-button-group class="justify-end">
        <flux-button ref="btn_cancel" type="default" @click="cancel">{{
          $t("general.cancel")
        }}</flux-button>
        <flux-submit-button
          v-if="state == 'create'"
          ref="btn_create"
          icon="fal fa-plus"
          @click="create"
          type="primary"
          >{{ $t("general.create") }}</flux-submit-button
        >
        <flux-submit-button
          v-if="state == 'update'"
          ref="btn_update"
          icon="fal fa-pencil"
          @click="update"
          type="primary"
          >{{ $t("general.update") }}</flux-submit-button
        >
      </flux-button-group>
    </flux-form>

    <div class="space-y-2" v-else-if="state == 'read'">
      <div v-for="phone_number in modelValue.phone_numbers">
        <span class="text-text-quaternary">
          <i class="far fa-phone-alt text-sm"></i>
          {{ $t(`phone_number.type.${phone_number.phone_number_type}`) }}
        </span>
        {{ phone_number.phone_number }}
      </div>
      <div v-for="email in modelValue.email_addresses">
        <span class="inline-block text-text-quaternary">
          <i class="far fa-envelope text-sm"></i>
          {{ $t(`email_address.type.${email.email_address_type}`) }}
        </span>
        {{ email.email_address }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { apiClient } from "@/libraries/utils/axios";
import ContactFormComponent from "./ContactForm.vue";
import { CRUDComponentState } from "../../contracts/CRUDComponent";
import { ContactForm } from "../../models/Contact";
import { Patient } from "@/composables/patient";
import { MessageBag } from "@/libraries/utils/MessageBag";
import { useNotify } from "@/composables/notify";
import { isValidationErrorResponse } from "@/libraries/utils/errorHandling";
import { $t } from "@/libraries/i18n";
import { ref, onMounted, nextTick } from "vue";
import { useConfirm } from "@/composables/confirm";

const { notify } = useNotify();

const modelValue = defineModel<Patient["contacts"][number] | ContactForm>(
  "modelValue",
  {
    required: true,
  },
);

const props = withDefaults(
  defineProps<{
    editable?: boolean;
    removeable?: boolean;
    patient: Patient;
  }>(),
  {
    editable: true,
    removeable: true,
  },
);

const emit = defineEmits<{
  (e: "removed"): void;
}>();

const errors = ref<MessageBag | undefined>(undefined);

const state = ref<CRUDComponentState>("read");
const loading = ref(false);

onMounted(() => {
  setState(getDefaultState());
});

async function create(): Promise<boolean> {
  loading.value = true;
  try {
    const res = await apiClient.post(
      `/patients/:zis_number/contacts`,
      modelValue.value,
      {
        params: {
          zis_number: props.patient.zis_number,
        },
      },
    );
    if (!res.data.success) {
      return false;
    }
    setState("read");
    notify({
      message: $t("contact.created") as string,
      type: "success",
    });
    errors.value = undefined;
    loading.value = false;
    return true;
  } catch (e) {
    if (isValidationErrorResponse(e)) {
      errors.value = MessageBag.fromResponse(e.response);
    }
    loading.value = false;
    throw e;
  }
}

async function update(): Promise<boolean> {
  if (!("id" in modelValue.value) || !modelValue.value.id) {
    throw new Error("tried to update contact, but contact is unpersisted");
  }

  loading.value = true;

  try {
    const res = await apiClient.put(
      "/patients/:zis_number/contacts/:id",
      modelValue.value,
      {
        params: {
          zis_number: props.patient.zis_number,
          id: modelValue.value.id,
        },
      },
    );
    if (!res.data.success) {
      return false;
    }
    setState("read");
    notify({
      message: $t("contact.updated") as string,
      type: "success",
    });
    errors.value = undefined;
    loading.value = false;
    return true;
  } catch (e) {
    if (isValidationErrorResponse(e)) {
      errors.value = MessageBag.fromResponse(e.response);
    }
    loading.value = false;
    return false;
  }
}

const { confirm } = useConfirm();

async function confirmRemove() {
  const shouldRemove = await confirm({
    message: $t("contact.confirm.text") as string,
    title: $t("contact.confirm.title") as string,
    type: "delete",
  });

  if (!shouldRemove) {
    return false;
  }

  return remove();
}

async function remove() {
  if (!props.removeable) {
    throw new Error("tried to remove contact, but removeable is false");
  }

  if (!("id" in modelValue.value) || !modelValue.value.id) {
    throw new Error("tried to remove contact, but contact is unpersisted");
  }

  loading.value = true;
  try {
    const res = await apiClient.delete("/patients/:zis_number/contacts/:id", {
      params: {
        zis_number: props.patient.zis_number,
        id: modelValue.value.id,
      },
    });
    loading.value = false;
    emit("removed");
    notify({
      message: $t("contact.removed") as string,
      type: "success",
    });
    return true;
  } catch (e) {
    loading.value = false;
    return false;
  }
}

async function cancel(): Promise<void> {
  if (
    !modelValue.value ||
    !("id" in modelValue.value) ||
    !modelValue.value.id
  ) {
    emit("removed");
    return;
  }

  // It is persisted, so return to view
  setState("read");
}

function setState(input: CRUDComponentState): void {
  switch (input) {
    case "create":
      state.value = "create";
      scrollIntoView();
      break;

    case "read":
      state.value = "read";
      break;

    case "update":
      state.value = "update";
      scrollIntoView();
      break;
  }
}

const root = ref<HTMLDivElement>();

function scrollIntoView() {
  nextTick(() => {
    if (root.value !== undefined) {
      root.value.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  });
}

function getDefaultState(): CRUDComponentState {
  if ("id" in modelValue.value) return "read";
  return "create";
}
</script>
