<template>
  <button
    :type="nativeButtonType"
    :disabled="disabled"
    @click="emit('click', $event)"
    :class="[
      'inline-flex transform items-center gap-2 rounded font-sans outline-none ring-opacity-50 focus:ring',
      'group m-0 box-border justify-center border transition duration-75 ',
      disabled
        ? 'cursor-not-allowed hover:!shadow-none focus:!ring-0'
        : 'cursor-pointer',
      size == 'mini'
        ? 'py-1 text-xs hover:shadow-sm'
        : size == 'small'
          ? 'px-3 py-2 text-sm hover:shadow-sm'
          : 'px-6 py-3 text-sm hover:shadow',
      type == 'primary'
        ? '  text-white' +
          (disabled
            ? ' border-blue-300 bg-blue-300  dark:border-blue-900 dark:bg-blue-900 dark:text-text-quaternary '
            : ' border-blue-500 bg-blue-500 hover:border-blue-600 hover:bg-blue-600 ')
        : type == 'warning'
          ? 'ring-orange-500 ' +
            (disabled
              ? ' border-orange-100 bg-orange-100 text-orange-400 dark:border-orange-900 dark:bg-orange-900 dark:text-orange-300'
              : ' border-orange-100 bg-orange-100 text-orange-700 hover:border-orange-200 hover:bg-orange-200  dark:border-orange-900 dark:bg-orange-900 dark:text-orange-100 hover:dark:border-orange-800  hover:dark:bg-orange-800')
          : type == 'error'
            ? 'ring-red-500 ' +
              (disabled
                ? ' border-red-100 bg-red-100 text-red-400 dark:border-red-500 dark:bg-red-600 dark:text-red-400'
                : ' border-red-100 bg-red-100 text-red-700 hover:border-red-200 hover:bg-red-200 dark:border-red-900 dark:bg-red-800 dark:text-red-100')
            : type == 'delete'
              ? 'ring-red-500 ' +
                (disabled
                  ? ' border-red-400 bg-red-400 text-red-50'
                  : ' border-red-600 bg-red-600 text-white hover:border-red-500 hover:bg-red-500')
              : type == 'default' || type == 'secondary'
                ? 'border-border-secondary bg-background-card shadow-sm ring-gray-400  dark:ring-neutral-400 ' +
                  (disabled
                    ? 'border-border-primary text-text-quaternary'
                    : 'text-text-secondary hover:bg-background-tertiary hover:text-text-primaryHover active:bg-background-secondary active:text-text-primaryActive')
                : type == 'text'
                  ? 'border-transparent bg-transparent shadow-none hover:!shadow-none ' +
                    (disabled
                      ? 'text-text-quaternary'
                      : 'text-blue-500 hover:text-text-primaryHover active:text-blue-700 dark:text-blue-400 hover:dark:text-blue-500')
                  : '',
    ]"
  >
    <i
      v-if="icon"
      :class="[icon, iconPosition === 'right' ? 'order-last' : 'order-first']"
    ></i>
    <slot></slot>
    <div
      v-if="hotkey && showHotkey"
      :class="[
        'flex items-center gap-x-1',
        hotkeyPosition === 'right' ? 'order-last' : 'order-first',
      ]"
    >
      <div
        :class="[
          'rounded-sm px-1 py-0.5',
          type === 'default' ? 'bg-blue-200/25' : 'bg-background-primary/25',
          type === 'text' ? 'border border-blue-200/75' : '',
        ]"
      >
        <i class="fal fa-arrow-alt-up" v-if="hotkey.shiftKey" />
        <i class="fal fa-arrow-alt-right" v-if="hotkey.key === 'arrowRight'" />
        <i
          class="fal fa-arrow-alt-left"
          v-else-if="hotkey.key === 'arrowLeft'"
        />
        <template v-else>
          {{ hotkey.key.toUpperCase() }}
        </template>
      </div>
    </div>
  </button>
</template>

<script lang="ts" setup>
import { PropType, toRef } from "vue";
import { Hotkey } from "@/models/Hotkey";
import { useHotkey } from "@/composables/hotkeyComposable";
import { ButtonType } from "@/models/Button";

const props = defineProps({
  nativeButtonType: {
    default: "button",
    type: String as PropType<"button" | "submit" | "reset">,
  },
  type: {
    default: "default",
    type: String as PropType<ButtonType>,
  },
  size: {
    default: "medium",
    type: String as PropType<"medium" | "small" | "mini">,
  },
  icon: {
    type: String,
  },
  iconPosition: {
    default: "left",
    type: String as PropType<"left" | "right">,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  hotkey: {
    type: Object as PropType<Hotkey>,
  },
  showHotkey: {
    default: true,
    type: Boolean,
  },
  hotkeyPosition: {
    default: "right",
    type: String as PropType<"left" | "right">,
  },
});

const emit = defineEmits<{
  (e: "click", v?: MouseEvent): void;
  (e: "hotkeyClick"): void;
}>();

useHotkey(toRef(props, "hotkey"), () => {
  emit("click");
  emit("hotkeyClick");
});
</script>
