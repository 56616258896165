export const colorStyles = {
  rose: {
    name: "rose",
    background: "bg-rose-100 dark:bg-rose-800",
    hover: "hover:bg-rose-200/80 hover:dark:bg-rose-700/80",
    ring: "ring-rose-500",
    border: "border-rose-500",
    outline: "outline-rose-500",
    text: "text-rose-900 dark:text-rose-100",
    active_text:
      "text-rose-50 active:text-rose-50 dark:text-rose-100 active:dark:text-rose-100",
    active_background: "bg-rose-400 dark:bg-rose-600",
  },
  red: {
    name: "red",
    background: "bg-red-100 dark:bg-red-800",
    hover: "hover:bg-red-200/80 hover:dark:bg-red-700/80",
    ring: "ring-red-500",
    border: "border-green-500",
    outline: "outline-red-500",
    text: "text-red-900 dark:text-red-100",
    active_text:
      "text-red-50 active:text-red-50 dark:text-red-100 active:text-red-100",
    active_background: "bg-red-400 dark:bg-red-600",
  },
  orange: {
    // the good one
    name: "orange",
    background: "bg-orange-100 dark:bg-orange-800",
    hover: "hover:bg-orange-200/80 hover:dark:bg-orange-700/80",
    ring: "ring-orange-600 dark:ring-orange-500",
    border: "border-orange-600 ",
    outline: "outline-orange-600 ",
    text: "text-orange-900 dark:text-orange-100",
    active_text:
      "text-orange-50 active:text-orange-50 dark:text-orange-100 active:dark:text-orange-100",
    active_background: "bg-orange-400 dark:bg-orange-600",
  },
  amber: {
    name: "amber",
    background: "bg-amber-100 dark:bg-amber-800",
    hover: "hover:bg-amber-200/80 hover:dark:bg-amber-700/80",
    ring: "ring-amber-500",
    border: "border-amber-500",
    outline: "outline-amber-500",
    text: "text-amber-900 dark:text-amber-100",
    active_text:
      "text-amber-50 active:text-amber-50 dark:text-amber-100 active:dark:text-amber-100",
    active_background: "bg-amber-400 dark:bg-amber-600",
  },
  yellow: {
    name: "yellow",
    background: "bg-yellow-100 dark:bg-yellow-600",
    hover: "hover:bg-yellow-200/80 hover:dark:bg-yellow-600/80",
    ring: "ring-yellow-500",
    border: "border-yellow-500",
    outline: "outline-yellow-500",
    text: "text-yellow-900 dark:text-yellow-100",
    active_text:
      "text-yellow-50 active:text-yellow-50 dark:text-yellow-100 active:dark:text-yellow-100",
    active_background: "bg-yellow-400 dark:bg-yellow-500",
  },
  lime: {
    name: "lime",
    background: "bg-lime-100 dark:bg-lime-800",
    hover: "hover:bg-lime-200/80 hover:dark:bg-lime-700/80",
    ring: "ring-lime-500",
    border: "border-lime-500",
    outline: "outline-lime-500",
    text: "text-lime-900 dark:text-lime-100",
    active_text:
      "text-lime-50 active:text-lime-50 dark:text-lime-100 active:dark:text-lime-100",
    active_background: "bg-lime-400 dark:bg-lime-600",
  },
  green: {
    name: "green",
    background: "bg-green-100 dark:bg-green-800",
    hover: "hover:bg-green-200/80 hover:dark:bg-green-700/80",
    ring: "ring-green-500",
    border: "border-green-500",
    outline: "outline-green-500",
    text: "text-green-900 dark:text-green-100",
    active_text:
      "text-green-50 active:text-green-50 dark:text-green-100 active:dark:text-green-100",
    active_background: "bg-green-400 dark:bg-green-600",
  },
  emerald: {
    name: "emerald",
    background: "bg-emerald-100 dark:bg-emerald-800",
    hover: "hover:bg-emerald-200/80 hover:dark:bg-emerald-700/80",
    ring: "ring-emerald-500",
    border: "border-emerald-500",
    outline: "outline-emerald-500",
    text: "text-emerald-900 dark:text-emerald-100",
    active_text:
      "text-emerald-50 active:text-emerald-50 dark:text-emerald-100 active:dark:text-emerald-100",
    active_background: "bg-emerald-400 dark:bg-emerald-600",
  },
  teal: {
    name: "teal",
    background: "bg-teal-100 dark:bg-teal-800",
    hover: "hover:bg-teal-200/80 hover:dark:bg-teal-700/80",
    ring: "ring-teal-500",
    border: "border-teal-500",
    outline: "outline-teal-500",
    text: "text-teal-900 dark:text-teal-100",
    active_text:
      "text-teal-50 active:text-teal-50 dark:text-teal-100 active:dark:text-teal-100",
    active_background: "bg-teal-400 dark:bg-teal-600",
  },
  cyan: {
    name: "cyan",
    background: "bg-cyan-100 dark:bg-cyan-800",
    hover: "hover:bg-cyan-200/80 hover:dark:bg-cyan-700/80",
    ring: "ring-cyan-500",
    border: "border-cyan-500",
    outline: "outline-cyan-500",
    text: "text-cyan-900 dark:text-cyan-100",
    active_text:
      "text-cyan-50 active:text-cyan-50 dark:text-cyan-100 active:dark:text-cyan-100",
    active_background: "bg-cyan-400 dark:bg-cyan-600",
  },
  sky: {
    name: "sky",
    background: "bg-sky-100 dark:bg-sky-800",
    hover: "hover:bg-sky-200/80 hover:dark:bg-sky-700/80",
    ring: "ring-sky-500",
    border: "border-sky-500",
    outline: "outline-sky-500",
    text: "text-sky-900 dark:text-sky-100",
    active_text:
      "text-sky-50 active:text-sky-50 dark:text-sky-100 active:dark:text-sky-100",
    active_background: "bg-sky-400 dark:bg-sky-600",
  },
  blue: {
    name: "blue",
    background: "bg-blue-100 dark:bg-blue-800",
    hover: "hover:bg-blue-200/80 hover:dark:bg-blue-700/80",
    ring: "ring-blue-600 dark:ring-blue-500",
    border: "border-blue-600 ",
    outline: "outline-blue-600 ",
    text: "text-blue-900 dark:text-blue-100",
    active_text:
      "text-blue-50 active:text-blue-50 dark:text-blue-100 active:dark:text-blue-100",
    active_background: "bg-blue-400 dark:bg-blue-600",
  },
  indigo: {
    name: "indigo",
    background: "bg-indigo-100 dark:bg-indigo-800",
    hover: "hover:bg-indigo-200/80 hover:dark:bg-indigo-700/80",
    ring: "ring-indigo-500",
    border: "border-indigo-500",
    outline: "outline-indigo-500",
    text: "text-indigo-900 dark:text-indigo-100",
    active_text:
      "text-indigo-50 active:text-indigo-50 dark:text-indigo-100 active:dark:text-indigo-100",
    active_background: "bg-indigo-400 dark:bg-indigo-600",
  },
  violet: {
    name: "violet",
    background: "bg-violet-100 dark:bg-violet-800",
    hover: "hover:bg-violet-200/80 hover:dark:bg-violet-700/80",
    ring: "ring-violet-500",
    border: "border-violet-500",
    outline: "outline-violet-500",
    text: "text-violet-900 dark:text-violet-100",
    active_text:
      "text-violet-50 active:text-violet-50 dark:text-violet-100 active:dark:text-violet-100",
    active_background: "bg-violet-400 dark:bg-violet-600",
  },
  purple: {
    name: "purple",
    background: "bg-purple-100 dark:bg-purple-800",
    hover: "hover:bg-purple-200/80 hover:dark:bg-purple-700/80",
    ring: "ring-purple-500",
    border: "border-purple-500",
    outline: "outline-purple-500",
    text: "text-purple-900 dark:text-purple-100",
    active_text:
      "text-purple-50 active:text-purple-50 dark:text-purple-100 active:dark:text-purple-100",
    active_background: "bg-purple-400 dark:bg-purple-600",
  },
  fuchsia: {
    name: "fuchsia",
    background: "bg-fuchsia-100 dark:bg-fuchsia-800",
    hover: "hover:bg-fuchsia-200/80 hover:dark:bg-fuchsia-700/80",
    ring: "ring-fuchsia-500",
    border: "border-fuchsia-500",
    outline: "outline-fuchsia-500",
    text: "text-fuchsia-900 dark:text-fuchsia-100",
    active_text:
      "text-fuchsia-50 active:text-fuchsia-50 dark:text-fuchsia-100 active:dark:text-fuchsia-100",
    active_background: "bg-fuchsia-400 dark:bg-fuchsia-600",
  },
  pink: {
    name: "pink",
    background: "bg-pink-100 dark:bg-pink-800",
    hover: "hover:bg-pink-200/80 hover:dark:bg-pink-700/80",
    ring: "ring-pink-500",
    border: "border-pink-500",
    outline: "outline-pink-500",
    text: "text-pink-900 dark:text-pink-100",
    active_text:
      "text-pink-50 active:text-pink-50 dark:text-pink-100 active:dark:text-pink-100",
    active_background: "bg-pink-400 dark:bg-pink-600",
  },
  gray: {
    name: "gray",
    background: "bg-background-secondary dark:bg-gray-800",
    hover: "hover:bg-background-quaternary/80 hover:dark:bg-gray-700/80",
    ring: "ring-gray-500",
    border: "border-gray-500",
    outline: "outline-gray-500",
    text: "text-gray-900 dark:text-gray-100",
    active_text:
      "text-gray-50 active:text-gray-50 dark:text-gray-100 active:dark:text-gray-100",
    active_background: "bg-gray-400 dark:bg-gray-600",
  },
} as const;

export type ColorStyleColor = keyof typeof colorStyles;
export type ColorStyle = (typeof colorStyles)[ColorStyleColor];
